export default [
  {
    path: '/shopee/accounts/add',
    name: 'add_shopee',
    component: () => import('@/views/shopee/accounts/Add.vue'),
    meta: {
      resource: 'Shopee',
      action: 'read',
    },
  },
]
