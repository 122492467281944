export default {
  namespaced: true,
  state: {
    dashboards: [],
    editMode: false,
  },
  getters: {
    dashboards: state => state.dashboards,
    editMode: state => state.editMode,
  },
  mutations: {
    UPDATE_EDIT_MODE: (state, value) => {
      state.editMode = value
    },
    UPDATE_DASHBOARDS: (state, value) => {
      state.dashboards = value
    },
  },
  actions: {
    loadDashboard: context => {
      const dashboard = JSON.parse(localStorage.getItem('dashboard'))
      if (dashboard) {
        const dashboards = []
        dashboards.push(dashboard)
        context.commit('UPDATE_DASHBOARDS', dashboards)
      } else {
        context.dispatch('loadDefaultDashboard')
      }
    },
    loadDefaultDashboard: context => {
      const dashboards = []
      const defaultDashboard = {
        options: {
          mode: 'vertical',
        },
        children: [
          {
            name: 'meliNotices',
            size: 12,
            options: {
              showTitle: true,
              showAccount: true,
              showRefresh: true,
            },
          },
          {
            name: 'contentComponent',
            size: 12,
            options: {
              mode: 'horizontal',
            },
            children: [
              {
                name: 'meliItems',
                size: 3,
                options: {
                  filterType: 'listing_type_id',
                  border: 'primary',
                },
              },
              {
                name: 'meliItems',
                size: 3,
                options: {
                  filterType: 'listing_type_id',
                  border: 'primary',
                },
              },
              {
                name: 'meliItems',
                size: 3,
                options: {
                  filterType: 'listing_type_id',
                  border: 'primary',
                },
              },
              {
                name: 'meliItems',
                size: 3,
                options: {
                  filterType: 'listing_type_id',
                  border: 'primary',
                },
              },
            ],
          },
          {
            name: 'contentComponent',
            options: {
              mode: 'horizontal',
            },
            children: [
              {
                name: 'contentComponent',
                size: 3,
                options: {
                  mode: 'vertical',
                },
                children:
                  [
                    {
                      name: 'meliItems',
                      size: 12,
                      options: {
                        filterType: 'listing_type_id',
                        border: 'primary',
                      },
                    },
                    {
                      name: 'meliItems',
                      size: 12,
                      options: {
                        filterType: 'listing_type_id',
                        border: 'primary',
                      },
                    },
                    {
                      name: 'meliItems',
                      size: 12,
                      options: {
                        filterType: 'listing_type_id',
                        border: 'primary',
                      },
                    },
                    {
                      name: 'meliItems',
                      size: 12,
                      options: {
                        filterType: 'listing_type_id',
                        border: 'primary',
                      },
                    },
                  ],
              },
              {
                name: 'contentComponent',
                size: 9,
                options: {
                  mode: 'horizontal',
                },
                children:
                  [
                    {
                      name: 'meliVisits',
                      size: 6,
                    },
                    {
                      name: 'meliVisits',
                      size: 6,
                    },
                  ],
              },
            ],
          },
        ],
      }

      dashboards.push(defaultDashboard)
      context.commit('UPDATE_DASHBOARDS', dashboards)
    },
    enableEditMode: context => {
      context.commit('UPDATE_EDIT_MODE', true)
    },
    disableEditMode: context => {
      context.commit('UPDATE_EDIT_MODE', false)
    },
  },
}
