import defaultConfig from '@core-md/config'
import Crypt from '@core-md/utils/crypt'
import authorizationsConfig from './config'

export default class AuthorizationsService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...authorizationsConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getCachedAuthorizations() {
    try {
      return JSON.parse(Crypt.decrypt(localStorage.getItem(this.jwtConfig.authorizationsKeyName)))
    } catch {
      return null
    }
  }

  setCachedAuthorizations(accounts) {
    localStorage.setItem(this.jwtConfig.authorizationsKeyName, Crypt.encrypt(JSON.stringify(accounts)))
  }

  hasAuthorizations() {
    try {
      const cached = this.getCachedAuthorizations()
      return (cached !== null && cached.length > 0)
    } catch {
      return false
    }
  }

  results(...args) {
    return this.axiosIns.post(defaultConfig.API_URL + this.jwtConfig.resultsEndpoint, ...args)
  }

  validateCodeMeli(...args) {
    return this.axiosIns.post(defaultConfig.API_URL + this.jwtConfig.validateCodeMeliEndpoint, ...args)
  }

  deletePermission(...args) {
    return this.axiosIns.delete(defaultConfig.API_URL + this.jwtConfig.deletePermissionEndpoint, { data: args[0] })
  }
}
