import defaultConfig from '@core-md/config'
import Crypt from '@core-md/utils/crypt'
import handleAxiosException from '@core-md/mixins/errors'
import meliConfig from './config'

export default class MeliService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...meliConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  callMeliProcess(body) {
    const token = Crypt.signToken(body)
    return this.process({
      request: token,
    })
      .then(response => {
        const result = Crypt.decrypt(response.data.result)
        return result
      })
      .catch(error => {
        handleAxiosException(error, 'system.about.messages.error')
      })
  }

  callMpProcess(body) {
    const token = Crypt.signToken(body)
    return this.processMp({
      request: token,
    })
      .then(response => {
        const result = Crypt.decrypt(response.data.result)
        return result
      })
      .catch(error => {
        handleAxiosException(error, 'system.about.messages.error')
      })
  }

  process(...args) {
    return this.axiosIns.post(defaultConfig.API_URL + this.jwtConfig.processEndpoint, ...args)
  }

  processMp(...args) {
    return this.axiosIns.post(defaultConfig.API_URL + this.jwtConfig.processMpEndpoint, ...args)
  }

  searchUser(nickname) {
    return this.axiosIns.get(`${defaultConfig.API_URL}${this.jwtConfig.searchUserEndpoint}/${nickname}.json`)
      .catch(error => {
        handleAxiosException(error, 'system.about.messages.error')
      })
  }
}
