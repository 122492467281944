export default [
  {
    path: '/meli',
    name: 'meli',
    component: () => import('@/layouts/containers/SubRoute.vue'),
    children: [
      {
        path: 'accounts',
        name: 'meli-accounts',
        component: () => import('@/layouts/containers/SubRoute.vue'),
        meta: {
          logged: true,
        },
        children: [
          {
            path: 'add',
            name: 'add_meli',
            component: () => import('@/views/meli/accounts/Add.vue'),
            meta: {
              logged: true,
            },
          },
          {
            path: 'profile',
            name: 'meli-accounts-profile',
            component: () => import('@/views/meli/accounts/profile/Main.vue'),
            meta: {
              logged: true,
              needAuthorizations: true,
            },
          },
        ],
      },
      {
        path: 'dashboard',
        name: 'meli-dashboard',
        component: () => import('@/views/meli/dashboards/Main.vue'),
        meta: {
          logged: true,
        },
      },
      {
        path: 'analytics',
        name: 'meli-analytics',
        component: () => import('@/layouts/containers/SubRoute.vue'),
        meta: {
          logged: true,
        },
        children: [
          {
            path: 'charts',
            name: 'meli-analytics-charts',
            component: () => import('@/views/meli/analytics/charts/Main.vue'),
            meta: {
              logged: true,
              needAuthorizations: true,
            },
          },
          {
            path: 'reports',
            name: 'meli-analytics-sales-reports',
            component: () => import('@/views/meli/analytics/sales-reports/Main.vue'),
            meta: {
              logged: true,
              needAuthorizations: true,
            },
          },
          {
            path: 'competitors',
            name: 'meli-analytics-competitors',
            component: () => import('@/views/meli/analytics/competitors/Main.vue'),
            meta: {
              logged: true,
              needAuthorizations: true,
            },
          },
        ],
      },
      {
        path: 'sales',
        name: 'meli-sales',
        component: () => import('@/views/meli/sales/Main.vue'),
        meta: {
          logged: true,
          needAuthorizations: true,
        },
      },
      {
        path: 'mp',
        name: 'meli-mp',
        component: () => import('@/layouts/containers/SubRoute.vue'),
        meta: {
          logged: true,
        },
        children: [
          {
            path: 'collections',
            name: 'meli-mp-collections',
            component: () => import('@/views/meli/mp/Main.vue'),
            meta: {
              logged: true,
              needAuthorizations: true,
            },
          },
        ],
      },
      {
        path: 'listings',
        name: 'meli-listings',
        component: () => import('@/views/meli/listings/Main.vue'),
        meta: {
          logged: true,
          needAuthorizations: true,
        },
      },
      {
        path: 'questions',
        name: 'meli-questions',
        component: () => import('@/views/meli/questions/Main.vue'),
        meta: {
          logged: true,
          needAuthorizations: true,
        },
      },
      {
        path: 'settings',
        name: 'meli-settings',
        component: () => import('@/layouts/containers/SubRoute.vue'),
        meta: {
          logged: true,
        },
        children: [
          {
            path: 'import',
            name: 'meli-settings-import',
            component: () => import('@/views/meli/settings/Import.vue'),
            meta: {
              logged: true,
              needAuthorizations: true,
            },
          },
          {
            path: 'blocklists',
            name: 'meli-settings-blocklists',
            component: () => import('@/views/meli/settings/Blocklists.vue'),
            meta: {
              logged: true,
              needAuthorizations: true,
            },
          },
        ],
      },
    ],
  },
]
