import meliUser from '@/services/meli/users'
// import Crypt from '@core-md/utils/crypt'
// import defaultConfig from '@core-md/config'
// import { fromUnixTime } from 'date-fns'

export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {
    users: state => state.users,
  },
  mutations: {
    LOAD_CACHED_USERS: state => {
      const cached = localStorage.getItem('cached_users')
      if (cached) {
        state.users = JSON.parse(cached)
      } else {
        state.users = []
      }
    },
    ADD_USER: (state, value) => {
      value.updated = new Date() // eslint-disable-line
      const userDetail = state.users.find(user => user.id === value.id)
      if (!userDetail) {
        state.users.push(value)
      } else {
        state.users = state.users.map(t => {
          if (t.id === value.id) {
            return value
          }
          return t
        })
      }
    },
  },
  actions: {
    initialize: context => {
      context.commit('LOAD_CACHED_USERS', [])
    },
    refreshUserDetails: ({ commit, getters }, params) => meliUser.getPublicUsers(params.accessToken, params.ids).then(response => {
      const users = []
      if (response.body) {
        response.body.forEach(element => {
          if (element.code === 200) {
            commit('ADD_USER', element.body)
            users.push(element.body)
          }
        })
        localStorage.setItem('cached_users', JSON.stringify(getters.users))
      }
      return users
    }),
  },
}
