export default [
  {
    path: '/profile',
    name: 'system-profile',
    component: () => import('@/views/system/settings/Profile.vue'),
    meta: {
      logged: true,
    },
  },
  {
    path: '/system/faq',
    name: 'system-faq',
    component: () => import('@/views/system/help/Help.vue'),
    meta: {
      logged: true,
    },
  },
  {
    path: '/system/about',
    name: 'system-about',
    component: () => import('@/views/system/About.vue'),
    meta: {
      logged: true,
    },
  },
  {
    path: '/system/settings',
    name: 'system-settings',
    component: () => import('@/views/system/settings/AccountSetting.vue'),
    meta: {
      logged: true,
    },
  },
]
