export default {
  // Endpoints
  resultsEndpoint: '/authorizations/results.json',
  validateCodeMeliEndpoint: '/authorizations/meliValidate.json',
  deletePermissionEndpoint: '/userPermissions/delete.json',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'auth_token',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserDataKeyName: 'userData',
  authorizationsKeyName: 'authorizations',
}
