import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Plugin } from 'vue2-storage'
import VueJWT from 'vuejs-jwt'
import VueClipboard from 'vue-clipboard2'
import VueCookies from 'vue-cookies'
import defaultConfig from '@core-md/config'

import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import './registerServiceWorker'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// JWT
const jwtOptions = {
  signKey: defaultConfig.JWT_KEY,
}

Vue.use(VueJWT, jwtOptions)

Vue.use(VueClipboard)
Vue.use(VueCookies)

Vue.use(Plugin, {
  prefix: 'app_',
  driver: 'local',
  ttl: 60 * 10 * 1000, // 10 minutos
  replacer: (key, value) => value,
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = process.env.NODE_ENV === 'production'

new Vue({
  router,
  store,
  i18n,
  components: {
    ToastificationContent,
  },
  methods: {
    showToast(variant, text, title, icon, timeout = 5000) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        timeout,
        hideProgressBar: false,
      })
    },
    showMessageSuccess(text, title, icon = 'CheckCircleIcon', timeout) {
      this.showToast('success', text, title, icon, timeout)
    },
    showMessageError(text, title, icon = 'XOctagonIcon', timeout) {
      this.showToast('danger', text, title, icon, timeout)
    },
    showMessageInfo(text, title, icon = 'InfoIcon', timeout) {
      this.showToast('info', text, title, icon, timeout)
    },
    showMessageWarning(text, title, icon = 'AlertTriangleIcon', timeout) {
      this.showToast('warning', text, title, icon, timeout)
    },
  },
  render: h => h(App),
}).$mount('#app')
