export default {
  // Endpoints
  loginEndpoint: '/users/login.json',
  registerEndpoint: '/users/register.json',
  forgotPasswordEndpoint: '/users/forgotPassword.json',
  resetPasswordEndpoint: '/users/resetPassword.json',
  changePasswordEndpoint: '/users/changePassword.json',
  validateEmailEndpoint: '/users/validateCode.json',
  resendCodeEndpoint: '/users/resendCode.json',
  changeEmailEndpoint: '/users/changeEmail.json',

  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'auth_token',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserDataKeyName: 'userData',
}
