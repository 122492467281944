export default {

  isNumeric(value) {
    if (/([0-9])$/.test(value)) {
      return Number(value)
    }
    return NaN
  },
  rawurlencode(str) {
    return encodeURIComponent(str)
      .replace(/!/g, '%21')
      .replace(/'/g, '%27')
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29')
      .replace(/\*/g, '%2A')
      .replace(/~/g, '%7E')
  },
}
