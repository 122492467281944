import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authorizations from './authorizations'
import dashboard from './dashboard'
import meli from './meli'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authorizations,
    dashboard,
    meli,
  },
  strict: process.env.DEV,
})
