export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/access_control/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      logged: false,
    },
  },
  {
    path: '/logout',
    name: 'auth-logout',
    component: () => import('@/views/access_control/Logout.vue'),
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/access_control/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      logged: false,
    },
  },
  {
    path: '/forgotPassword',
    name: 'auth-forgot-password',
    component: () => import('@/views/access_control/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      logged: false,
    },
  },
  {
    path: '/resetPassword/:email',
    name: 'auth-reset-password',
    component: () => import('@/views/access_control/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      logged: false,
    },
  },
  {
    path: '/validateEmail/:id/:code?',
    name: 'auth-validate-email',
    component: () => import('@/views/access_control/ValidateEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      logged: false,
    },
  },
]
