export default {
  methods: {
    handleAxiosException(error, titleMessageKey) {
      let message = ''
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        message = this.$i18n.t('common.messages.errors.response_error')
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request)
        message = this.$i18n.t('common.messages.errors.no_response')
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
        message = error.message
      }
      this.$root.showMessageError(message, this.$i18n.t(titleMessageKey))
    },
  },
}
