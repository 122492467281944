export default [
  {
    path: '/accounts/:initialize?',
    name: 'accounts',
    component: () => import('@/views/pages/Accounts.vue'),
    meta: {
      resource: 'Logged',
      action: 'read',
      logged: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      resource: 'Logged',
      action: 'read',
      logged: true,
      // needAuthorizations: true,
    },
  },
]
