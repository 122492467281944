import CryptoJS from 'crypto-js'
import Jwt from 'jsonwebtoken'
import defaultConfig from '@core-md/config'

export default {

  decrypt(data) {
    const CryptoJSAesJson = {
      stringify(cipherParams) {
        const j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
        if (cipherParams.iv) j.iv = cipherParams.iv.toString()
        if (cipherParams.salt) j.s = cipherParams.salt.toString()
        return JSON.stringify(j)
      },
      parse(jsonStr) {
        const j = JSON.parse(jsonStr)
        const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams
      },
    }
    return JSON.parse(CryptoJS.AES.decrypt(data, defaultConfig.CSK_KEY, { format: CryptoJSAesJson }).toString(CryptoJS.enc.Utf8))
  },
  encrypt(data) {
    const CryptoJSAesJson = {
      stringify(cipherParams) {
        const j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) }
        if (cipherParams.iv) j.iv = cipherParams.iv.toString()
        if (cipherParams.salt) j.s = cipherParams.salt.toString()
        return JSON.stringify(j)
      },
      parse(jsonStr) {
        const j = JSON.parse(jsonStr)
        const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(j.ct) })
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
        return cipherParams
      },
    }
    return CryptoJS.AES.encrypt(JSON.stringify(data), defaultConfig.CSK_KEY, { format: CryptoJSAesJson }).toString()
  },
  signToken(body) {
    const jwtOptions = {
      algorithm: 'HS512',
      noTimestamp: true,
    }

    const token = Jwt.sign(body, defaultConfig.JWT_KEY, jwtOptions)
    return this.encrypt(token)
  },
}
