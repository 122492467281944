module.exports = {
  API_URL: process.env.VUE_APP_ROOT_API,
  JWT_KEY: 'eb0298a2af48ef1139370ab8659762a1573cab19bee1eca60b6efea32eb3ea62',
  CSK_KEY: '78a13edfb28fc840',
  TIMEZONE_FORMAT_COMPLETE: '"YYYY-MM-DDTHH:mm:ss.000-03:00"',
  APP_ID: '1585555766651492',
  VERSION_ID: 'v202311.1',
  RECAPTCHA_KEY: '6LfdkXwUAAAAAFi9xzNyQC3bWz98qv-sS6s5cgf7',
  MELI_MULTIGET_QTY: 20,
}
