export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/firstPage',
    name: 'first-page',
    component: () => import('@/views/pages/FirstPage.vue'),
    meta: {
      logged: true,
    },
  },
  {
    path: '/secondPage',
    name: 'second-page',
    component: () => import('@/views/pages/SecondPage.vue'),
    meta: {
      logged: true,
    },
  },
]
