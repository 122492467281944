import { fromUnixTime } from 'date-fns'
import authorizationsClient from '@core-md/services/authorizations/client'
import Crypt from '@core-md/utils/crypt'
import defaultConfig from '@core-md/config'
import meliUser from '@/services/meli/users'

export default {
  namespaced: true,
  state: {
    accounts: [],
    statusMessage: '',
    loadingCounter: 0,
    maxCounter: 100,
    selectedAccount: null,
    accountsLoaded: false,
    refreshTimer: null,
  },
  getters: {
    loadingCounter: state => state.loadingCounter,
    maxCounter: state => state.maxCounter,
    accounts: state => state.accounts,
    selectedAccount: state => state.selectedAccount,
    statusMessage: state => state.statusMessage,
    accountsLoaded: state => state.accountsLoaded,
    refreshTimer: state => state.refreshTimer,
  },
  mutations: {
    ADD_LOADING_COUNTER: (state, value) => {
      state.loadingCounter += value
      if (state.loadingCounter >= state.maxCounter) {
        state.accountsLoaded = true
      }
    },
    UPDATE_LOADING_COUNTER: (state, value) => {
      state.loadingCounter = value
    },
    UPDATE_MAX_COUNTER: (state, value) => {
      state.maxCounter = value
    },
    UPDATE_STATUS_MESSAGE: (state, message) => {
      state.statusMessage = message
    },
    UPDATE_ACCOUNTS_LOADED: (state, value) => {
      state.loadingCounter = state.maxCounter
      state.accountsLoaded = value
    },
    UPDATE_AUTHORIZATIONS: (state, accounts) => {
      state.accounts = accounts
    },
    CLEAR_SELECTED_ACCOUNT: state => {
      state.selectedAccount = null
    },
    CHANGE_SELECTED_ACCOUNT: (state, sellerId) => {
      state.accounts.forEach(account => {
        if (account.seller_id === sellerId) {
          state.selectedAccount = account
        }
      })
    },
    ADD_ACCOUNT: (state, account) => {
      let exists = false
      state.accounts.forEach(value => {
        if (value.seller_id === account.seller_id) {
          exists = true
        }
      })
      if (!exists) {
        state.accounts.push(account)
      }
      function compare(a, b) {
        if (a.seller_id < b.seller_id) {
          return -1
        }
        if (a.seller_id > b.seller_id) {
          return 1
        }
        return 0
      }
      state.accounts.sort(compare)
      // if (state.accounts.length === 1) {
      // state.selectedAccount = state.accounts[0]
      // }
      authorizationsClient.setCachedAuthorizations(state.accounts)
    },
    DELETE_ACCOUNT: (state, sellerId) => {
      const filtered = state.accounts.filter(account => account.seller_id !== sellerId)
      state.accounts = filtered
      authorizationsClient.setCachedAuthorizations(state.accounts)
    },
    UPDATE_REFRESH_TIMER: (state, refreshTimer) => {
      state.refreshTimer = refreshTimer
    },
  },
  actions: {
    initialize: context => {
      context.commit('UPDATE_AUTHORIZATIONS', [])
      context.commit('CLEAR_SELECTED_ACCOUNT')
      context.commit('UPDATE_LOADING_COUNTER', 10)
      context.commit('UPDATE_MAX_COUNTER', 100)
      context.commit('UPDATE_STATUS_MESSAGE', '')
      context.commit('UPDATE_ACCOUNTS_LOADED', false)
      if (context.getters.refreshTimer) {
        clearTimeout(context.getters.refreshTimer)
        context.commit('UPDATE_REFRESH_TIMER', null)
      }
    },
    refreshAuthorizations: context => {
      const cachedAuthorizations = authorizationsClient.getCachedAuthorizations()
      let expired = false
      if (cachedAuthorizations) {
        try {
          if (cachedAuthorizations && cachedAuthorizations.length > 0) {
            cachedAuthorizations.forEach(element => {
              if (fromUnixTime(element.expires) < new Date()) {
                expired = true
              }
            })
          } else {
            expired = true
          }
        } catch (e) {
          context.commit('UPDATE_AUTHORIZATIONS', [])
          expired = true
        }
      } else {
        expired = true
      }
      if (expired) {
        context.dispatch('getAuthorizations')
      } else {
        context.commit('UPDATE_AUTHORIZATIONS', cachedAuthorizations)
        if (!context.getters.selectedAccount) {
          if (cachedAuthorizations.length > 0) {
            context.commit('CHANGE_SELECTED_ACCOUNT', cachedAuthorizations[0].seller_id)
          }
        }
        context.commit('UPDATE_ACCOUNTS_LOADED', true)
      }
      const refreshTimer = setTimeout(() => {
        console.log('Disparando refresh')
        context.dispatch('refreshAuthorizations')
      }, 300000)
      context.commit('UPDATE_REFRESH_TIMER', refreshTimer)
    },
    getAuthorizations: context => {
      context.commit('UPDATE_STATUS_MESSAGE', 'accounts.verifying_identity')
      context.commit('UPDATE_LOADING_COUNTER', 10)
      const criteria = { type: 'meli_0,shopee' }
      const token = Crypt.signToken(criteria)
      authorizationsClient.results({
        request: token,
      })
        .then(response => {
          context.commit('UPDATE_STATUS_MESSAGE', 'accounts.loading_associated_accounts')
          context.commit('UPDATE_LOADING_COUNTER', 30)
          const accounts = Crypt.decrypt(response.data.result)
          if (accounts && accounts.length > 0) {
            context.commit('UPDATE_MAX_COUNTER', 30 + (accounts.length * 10))
            /* eslint-disable no-param-reassign */
            accounts.forEach(authorization => {
              authorization.loading = 10
              context.commit('ADD_ACCOUNT', authorization)
              if (!context.getters.selectedAccount) {
                context.commit('CHANGE_SELECTED_ACCOUNT', authorization.seller_id)
              }
              switch (authorization.type) {
                case 'meli_0':
                  authorization.access_token = 'APP_USR-' + defaultConfig.APP_ID + '-' + authorization.access_token + '-' + authorization.seller_id // eslint-disable-line prefer-template
                  authorization.loading = 50
                  context.commit('ADD_ACCOUNT', authorization)
                  meliUser.getUser(authorization.access_token, authorization.seller_id).then(result => {
                    if (result.httpCode === 200) {
                      if (!result.body.seller_reputation.level_id) {
                        result.body.seller_reputation.level_id = '0_grey'
                      }
                      result.body.updated = new Date()
                      // Vue.set(authorization, 'seller', result.body)
                      authorization.seller = result.body
                      authorization.loading = 70
                      meliUser.getShippingPreferences(authorization.access_token, authorization.seller_id).then(resultPreferences => {
                        if (resultPreferences.httpCode === 200) {
                          authorization.seller.shipping_modes = resultPreferences.body.modes
                          authorization.loading = 100
                          context.commit('ADD_ACCOUNT', authorization)
                        }
                      }).catch(data => {
                        context.commit('UPDATE_STATUS_MESSAGE', data)
                      })
                    }
                    context.commit('ADD_LOADING_COUNTER', 10)
                  }).catch(data => {
                    context.commit('UPDATE_STATUS_MESSAGE', data)
                  })
                  break
                case 'shopee':
                  context.commit('ADD_LOADING_COUNTER', 10)
                  /*
                  ShopeeProxy.getSeller(authorization.access_token, authorization.seller_id).then(function (result) {
                    // TODO: tratar casos de erro
                    if (result.httpCode === 200) {
                      result.body.updated = moment()
                      result.body.id = result.body.shop_id
                      result.body.nickname = result.body.shop_name
                      let seller = result.body
                      ShopeeProxy.getSellerPerformance(authorization.access_token, authorization.seller_id).then(function (result) {
                        // TODO: tratar casos de erro
                        if (result.httpCode === 200) {
                          seller.performance = result.body
                          Vue.set(authorization, 'seller', seller)
                          context.commit('addAccount', authorization)
                        }
                        context.commit('addLoadingCounter', 10)
                      }).catch(function (data) {
                        // context.commit('setStatusMessage', data)
                      })
                    }
                  }).catch(function (data) {
                    // context.commit('setStatusMessage', data)
                  })
                  */
                  break
                default:
                  break
              }
            })
          } else {
            context.commit('UPDATE_MAX_COUNTER', 30)
            context.commit('UPDATE_LOADING_COUNTER', 30)
            context.commit('UPDATE_ACCOUNTS_LOADED', true)
            context.commit('UPDATE_STATUS_MESSAGE', '')
          }
        })
        .catch(error => {
          context.commit('UPDATE_STATUS_MESSAGE', 'accounts.messages.error')
          console.log(error)
          // this.handleAxiosException(error, 'system.about.messages.error')
        })
    },
  },
}
