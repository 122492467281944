import Vue from 'vue'
import VueRouter from 'vue-router'

// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@core-md/services/auth/useJwt'
import authorizationsClient from '@core-md/services/authorizations/client'

import auth from './routes/auth'
import general from './routes/general'
import system from './routes/system'
import pages from './routes/pages'
import meli from './routes/meli'
import shopee from './routes/shopee'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...auth,
    ...general,
    ...system,
    ...pages,
    ...meli,
    ...shopee,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = useJwt.isUserLoggedIn()

  if (!isLoggedIn
    && (to.meta.logged === undefined || to.meta.logged === true)) {
    return next({ name: 'auth-login' })
  }

  if (to.meta.needAuthorizations) {
    const hasAuthorizations = authorizationsClient.hasAuthorizations()
    if (!hasAuthorizations) {
      return next({ name: 'accounts' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
