import stringUtil from '@core-md/utils/string'
import meliClient from './core/client'

export default {
  searchUser(accessToken, value) {
    const search = value.trim()
    if (stringUtil.isNumeric(search)) {
      return this.getUser(accessToken, search)
    }
    return this.searchUserByNickname(accessToken, search).then(response => {
      if (response.status === 200 && response.data.result.seller) {
        return this.getUser(accessToken, response.data.result.seller.id)
      }
      return response
    })
  },
  searchUserByNickname(accessToken, nickname) {
    /*
    const body = {
      method: 'GET',
      url: '/sites/MLB/search',
      parameters: {
        nickname: stringUtil.rawurlencode(nickname.toUpperCase().replace(' ', '+')).replace(/%2B/g, '+'),
        access_token: accessToken,
      },
    }
    */
    return meliClient.searchUser(nickname)
  },
  getUser(accessToken, id) {
    const body = {
      method: 'GET',
      url: `users/${id}`,
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getPublicUsers(accessToken, userIds, attributes = 'id,nickname,points,permalink') {
    const body = {
      method: 'GET',
      url: 'users',
      parameters: {
        access_token: accessToken,
        ids: userIds.join(','),
        attributes,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getShippingPreferences(accessToken, sellerId) {
    const body = {
      method: 'GET',
      url: `users/${sellerId}/shipping_preferences`,
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getItemsVisits(accessToken, sellerId) {
    const body = {
      method: 'GET',
      url: `users/${sellerId}/items_visits/time_window`,
      parameters: {
        access_token: accessToken,
        last: 30,
        unit: 'day',
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getOrdersBlocklist(request) {
    const body = {
      method: 'GET',
      url: `users/${request.sellerId}/order_blacklist`,
      parameters: {
        access_token: request.accessToken,
        limit: request.limit,
        offset: request.offset,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getQuestionsBlocklist(request) {
    const body = {
      method: 'GET',
      url: `users/${request.sellerId}/questions_blacklist`,
      parameters: {
        access_token: request.accessToken,
        limit: request.limit,
        offset: request.offset,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  searchUserOrderBlocklist(accessToken, sellerId, userId) {
    const body = {
      method: 'GET',
      url: `users/${sellerId}/order_blacklist/${userId}`,
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  searchUserQuestionBlocklist(accessToken, sellerId, userId) {
    const body = {
      method: 'GET',
      url: `users/${sellerId}/questions_blacklist/${userId}`,
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  addToOrderBlocklist(accessToken, sellerId, userId) {
    const body = {
      method: 'POST',
      url: `users/${sellerId}/order_blacklist`,
      body: {
        user_id: userId,
      },
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  addToQuestionBlocklist(accessToken, sellerId, userId) {
    const body = {
      method: 'POST',
      url: `users/${sellerId}/questions_blacklist/${userId}`,
      body: {
        user_id: userId,
      },
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  deleteUserFromOrdersBlocklist(accessToken, sellerId, userId) {
    return this.deleteUserFromBlocklist(accessToken, sellerId, userId, 'order')
  },
  deleteUserFromQuestionsBlocklist(accessToken, sellerId, userId) {
    return this.deleteUserFromBlocklist(accessToken, sellerId, userId, 'questions')
  },
  deleteUserFromBlocklist(accessToken, sellerId, userId, blocklist) {
    const body = {
      method: 'DELETE',
      url: `users/${sellerId}/${blocklist}_blacklist/${userId}`,
      parameters: {
        access_token: accessToken,
      },
    }
    return meliClient.callMeliProcess(body)
  },
}
